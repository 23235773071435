import { IProductDetailPayment } from "../../../@types/IProduct/IProductDetail";
import { IProductAccessory, IProductAccessoryPayment } from "../../../@types/IProduct/IProductAccessory";
import moment from "moment/moment";
import { IGeneralSetting, IHolidays } from "../../../@types/IContext/IAppContext";
import { calSurchargeDay } from "../../../utils/payment";

export const handleChangeQty = (
    carts: IProductDetailPayment[] | any,
    item: IProductDetailPayment,
    is_accessory: boolean,
    value: number,
    is_purchase: boolean,
    parent_id?: number
) => {
    let cloneCarts = [...carts];

    //console.log("1.0", is_accessory);
    if (is_accessory) {
        //console.log("1.1");

        let indexParent = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === parent_id && cart.is_purchase === is_purchase);
        if (item?.take_date && item?.return_date) {
            //console.log("1.2");
            indexParent = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === parent_id && cart.is_purchase === is_purchase && moment(cart.take_date).format("DD/MM/YYYY") === moment(item.take_date).format("DD/MM/YYYY") && moment(cart.return_date).format("DD/MM/YYYY") === moment(item.return_date).format("DD/MM/YYYY"));
        }

        if (indexParent === -1) return;

        let indexChild = cloneCarts[indexParent].accessories.findIndex((cartChild: IProductAccessory | any) => cartChild.id === item.id);
        if (indexChild === -1) return;

        let cloneChild = [...cloneCarts[indexParent].accessories];
        cloneChild[indexChild].qty = value;
        //console.log("cloneChild[indexChild]", cloneChild);

        cloneCarts[indexParent].accessories = cloneChild;
        //console.log('cloneCarts[indexParent].accc', cloneCarts[indexParent].accessories);

        //handle set
        if (cloneCarts[indexParent].is_set === true) {
            //console.log("1.3");
            let lowestQty = cloneCarts[indexParent]?.accessories[0]?.qty ?? 0;
            cloneCarts[indexParent]?.accessories?.forEach((el: any) => {
                if (el.qty < lowestQty && !el.bundling_id) {
                    lowestQty = el.qty
                }
            });
            //console.log("lowestQty", lowestQty);

            cloneCarts[indexParent].qty = lowestQty;
        }
    } else {
        //console.log("1.4", value);
        let indexParent = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === item.id && cart.is_purchase === is_purchase && moment(cart.take_date).format("DD/MM/YYYY") === moment(item.take_date).format("DD/MM/YYYY") && moment(cart.return_date).format("DD/MM/YYYY") === moment(item.return_date).format("DD/MM/YYYY"));

        cloneCarts[indexParent].qty = value;
        //console.log("1.4.1", cloneCarts);
        if (!cloneCarts[indexParent]?.is_bundling) {
            //console.log("1.5");
            cloneCarts[indexParent].accessories = cloneCarts[indexParent].accessories?.map((cardChild: IProductAccessory | any) => {
                return {
                    ...cardChild,
                    qty: cloneCarts[indexParent].is_product === false && !cardChild.bundling_id ? cardChild.product_amount * value : !cardChild.bundling_id ? value : cardChild.qty,
                }
            });
        } else {
            //console.log("1.6");
            cloneCarts[indexParent].accessories = cloneCarts[indexParent].accessories?.map((cardChild: IProductAccessory | any) => {
                //console.log("1.6", cardChild);
                return {
                    ...cardChild,
                    qty: cloneCarts[indexParent].is_product === false && !cardChild.bundling_id ? cardChild.product_amount * value : !cardChild.bundling_id ? value : cardChild.qty,
                }
            });
        }

    }

    //console.log("cloneCarts", cloneCarts);
    return cloneCarts;
}

export const checkItem = (
    carts: IProductDetailPayment[],
    products: IProductDetailPayment[] | IProductAccessoryPayment[] | any,
    value: boolean,
    is_purchase: boolean,
    parent_id?: number,
    take_date?: Date | any,
    return_date?: Date | any,
    orginItem?: IProductDetailPayment | IProductAccessoryPayment
) => {

    const cloneCarts = [...carts];
    let index = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === parent_id && cart.is_purchase === is_purchase);
    if (take_date && return_date) {
        index = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === parent_id && cart.is_purchase === is_purchase && moment(cart.take_date).format("DD/MM/YYYY") === moment(take_date).format("DD/MM/YYYY") && moment(cart.return_date).format("DD/MM/YYYY") === moment(return_date).format("DD/MM/YYYY"));
    }

    if (parent_id) {
        if (index === -1) {
            return cloneCarts;
        }
        const accessories = cloneCarts[index].accessories;
        let countChecked = 0;
        // console.log("accessories", accessories);
        // console.log("products", products);
        //products = products.filter((item: any) => !item.bundling_id)
        products.forEach((product: IProductDetailPayment) => {
            let indexChild = accessories.findIndex((cart: IProductAccessoryPayment) => cart.id === product.id && cloneCarts[index].is_purchase === is_purchase);
            //console.log("indexChild", indexChild);
            if (take_date && return_date) {
                //console.log("inn 1232", indexChild);
                indexChild = accessories.findIndex((cart: IProductAccessoryPayment) => cart.id === product.id && cloneCarts[index].is_purchase === is_purchase && moment(cart.take_date).format("DD/MM/YYYY") === moment(product.take_date).format("DD/MM/YYYY") && moment(cart.return_date).format("DD/MM/YYYY") === moment(product.return_date).format("DD/MM/YYYY"))
                //console.log("inn 1232646", indexChild);
            }

            if (indexChild !== -1) {
                //console.log("innn", value);
                accessories[indexChild].checked = value;
            }

            if (accessories[indexChild]?.checked) {
                //console.log("test", accessories[indexChild]);

                if (!accessories[indexChild]?.bundling_id || indexChild === 0) {
                    countChecked += 1;
                }
            }
        })

        cloneCarts[index].accessories = accessories;
        //console.log("countChecked", countChecked, products.length);

        //]cloneCarts[index].checked = countChecked === products.length;
        //console.log("test 9999", cloneCarts[index].accessories.filter((e: any) => !e.bundling_id));
        //console.log("cloneCarts[index].checked", cloneCarts[index].checked);
        if ((products?.bundling_id || products[0]?.bundling_id) && cloneCarts[index].accessories.filter((e: any) => !e.bundling_id).length > 0) {
            //console.log("Inn 5555", cloneCarts);

            return cloneCarts;
        }
        //console.log("products", products);
        cloneCarts[index].checked = value;
        products = products.filter((item: any) => !item.bundling_id);

        if (accessories.filter((e: any) => !e.bundling_id).length == accessories.length) {
            cloneCarts[index].checked = countChecked === cloneCarts[index].accessories.length;
        } else if (accessories.filter((e: any) => !e.bundling_id).length === 0 && orginItem?.code === cloneCarts[index]?.code) {
            cloneCarts[index].checked = value;
        }
        else if (accessories.filter((e: any) => !e.bundling_id && e.checked === true).length == accessories.filter((e: any) => !e.bundling_id).length) {
            cloneCarts[index].checked = true;
        } else if (accessories.filter((e: any) => !e.bundling_id && e.checked === false).length > 0) {
            cloneCarts[index].checked = false;
        }

        //Check set cho đặt lẻ và ko lẻ
        if (cloneCarts[index].allowed_split === false) {
            if (!orginItem?.bundling_id) {
                cloneCarts[index].checked = value;
                cloneCarts[index].accessories?.map((acc: any) => {
                    acc.checked = value;
                    return acc;
                })
            }
        }

        if (countChecked === 0 && countChecked === products.length && accessories?.filter((e: any) => e.bundling_id).length !== accessories.length) {
            cloneCarts[index].checked = false
        }
        //console.log("value", value);
        //console.log("countChecked", countChecked);
        //console.log('products', products);

        //console.log("cloneCarts[index].checked", cloneCarts[index].checked);

        if (products.length === 0) {
            if (accessories.length === accessories.filter((e: any) => e.checked === true).length) {
                //console.log("innn232.1", value);
                cloneCarts[index].checked = true
            } else if (accessories.filter((e: any) => e.checked === true && !e.bundling_id).length === accessories.filter((e: any) => !e.bundling_id).length && accessories.filter((e: any) => !e.bundling_id).length !== 0) {
                //console.log("innn232.2", value);
                cloneCarts[index].checked = true
            } else if (accessories.filter((e: any) => e.bundling_id).length === accessories.length && accessories.filter((e: any) => e.checked === true && e.bundling_id).length > 0) {
                cloneCarts[index].checked = true
            } else if (accessories.filter((e: any) => e.bundling_id).length === accessories.length) {
                //console.log("innn232.3", cloneCarts[index].checked);
                //cloneCarts[index].checked = false
            } else {
                //console.log("innn232.4", value);
                cloneCarts[index].checked = false
            }

        }


        if (cloneCarts[index]?.is_product === false && cloneCarts[index].is_purchase === is_purchase) {
            cloneCarts[index].checked = value;
            const accessories = cloneCarts[index].accessories;

            cloneCarts[index].accessories = accessories?.map((item: any) => {
                item.checked = value
                return item;
            });
        }
    } else {
        products.forEach((product: IProductDetailPayment) => {
            let index = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === product.id && cart.is_purchase === is_purchase && cart.is_product === product.is_product)
            if (take_date && return_date) {
                index = cloneCarts.findIndex((cart: IProductDetailPayment) => cart.id === product.id && cart.is_purchase === is_purchase && cart.is_product === product.is_product && moment(cart.take_date).format("DD/MM/YYYY") === moment(take_date).format("DD/MM/YYYY") && moment(cart.return_date).format("DD/MM/YYYY") === moment(return_date).format("DD/MM/YYYY"))
            }
            if (index !== -1) {
                cloneCarts[index].checked = value;
            }
        })
    }

    return cloneCarts;
}

export const totalProduct = (product: IProductDetailPayment, isDeposit: boolean) => {
    let returnValue: number | any = 0;
    let isFull = checkProductIsFullSet(product);

    if (product.accessories?.length && !isFull && product.is_product === true && !product?.is_bundling && !product?.is_main_bundling) {
        //console.log("In 1");
        let cloneAccessories = [...product.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];

        if (cloneAccessories.length === product.temp_accessories.length && product.temp_accessories.length > 0) {
            cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
            let qtyStandard = 0
            cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                if (index === 0) {
                    qtyStandard = acc.qty ?? 0;

                    returnValue += ((product.is_purchase ? product.price : product.rental_price) * qtyStandard)
                } else {
                    returnValue += (product.is_purchase ? acc.price : acc.rental_price) * ((acc.qty ?? 0) - qtyStandard);
                }
            })
        } else {

            product.accessories.forEach((acc: IProductAccessoryPayment) => {
                if (acc.checked) {
                    returnValue += (product.is_purchase ? acc.price : acc.rental_price) * (acc.qty ?? 0);
                }
            })
        }
    } else if (product?.is_bundling === true && product?.is_main_bundling === true) {
        //console.log("In 2", returnValue);

        if (!product.checked && !product?.allowed_split) {
            return 0
        };

        if (product.checked) {
            returnValue += ((product.is_purchase ? product.price : product.rental_price)) * (product?.qty ?? 1);
        }
        let cloneAccessories = [...product.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];

        if (cloneAccessories.length === product.temp_accessories.length && product.temp_accessories.length > 0) {
            //console.log("In 2.1");
            cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
            let qtyStandard = 0

            cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {


                if (acc.bundling_id && acc.checked) {

                    returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * ((acc.qty ?? 0) - qtyStandard);
                    //console.log("In 2.1.1", returnValue);
                }

            })
        } else {
            product.accessories.forEach((acc: IProductAccessoryPayment) => {
                if (acc.checked && acc.bundling_id) {
                    returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * (acc.qty ?? 0);
                }
                if (product.allowed_split && !product.checked && acc?.qty && !acc.bundling_id && acc.checked) {

                    returnValue += ((product.is_purchase ? acc.price : acc.rental_price) * (acc.qty ?? 0));
                    //console.log("In 3.2.1", returnValue);
                }
            })
        }

    } else {
        //console.log("In 3666", product);
        //xử lý bán kèm
        let find_attached_accessories = [...product.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.bundling_id)];
        if (find_attached_accessories && find_attached_accessories.length > 0 && !product.checked) {
            find_attached_accessories?.map((acc: IProductAccessoryPayment, index: number) => {
                returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * (acc.qty ?? 0);
                return acc;
            })
            //return returnValue;
        }
        //console.log("product", product);

        if (!product.checked && !product?.allowed_split) {
            return 0
        };

        if (product.checked) {
            returnValue += ((product.is_purchase ? product.price : product.rental_price)) * (product?.qty ?? 1);
        }


        //Sp bán kèm
        // let bundling_item = product.accessories.find((item: any) => item.bundling_id);
        // if (bundling_item) {
        //     returnValue += (product.is_purchase ? ((isDeposit ? bundling_item.original_price : bundling_item.price) ?? 0) : ((isDeposit ? bundling_item.original_rental_price : bundling_item.rental_price) ?? 0)) * (bundling_item.qty ?? 0);
        // }
        let cloneAccessories = [...product.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];
        //console.log("Inn 3.1", cloneAccessories, product);

        if (cloneAccessories.length === product.temp_accessories.length && product.temp_accessories.length > 0) {
            //console.log("In 3.1");
            cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
            cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                if (product.checked && (product.qty ?? 0) < (acc.qty ?? 0) && !acc.bundling_id) {
                    returnValue += ((product.is_purchase ? acc.price : acc.rental_price) * ((acc.qty ?? 0) - (product.qty ?? 0)))
                }
                if (acc.bundling_id) {
                    returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * (acc.qty ?? 0);
                }
                // if (index === 0) {
                //     qtyStandard = acc.qty ?? 0;

                //     returnValue += ((product.is_purchase ? product.price : product.rental_price) * qtyStandard)
                // } else {
                //     returnValue += (product.is_purchase ? acc.price : acc.rental_price) * ((acc.qty ?? 0) - qtyStandard);
                // }
            })
            //console.log("returnValue", returnValue);
        } else {
            //console.log("In 3.2", returnValue);
            product.accessories.forEach((acc: IProductAccessoryPayment) => {
                if (acc.checked) {
                    if (product.checked && product.qty !== 0 && acc.qty !== 0 && (product.qty ?? 0) < (acc.qty ?? 0) && !acc.bundling_id) {

                        returnValue += ((product.is_purchase ? acc.price : acc.rental_price) * ((acc.qty ?? 0) - (product.qty ?? 0)));
                    }
                    //Xử lý set lẻ
                    if (product.allowed_split && !product.checked && acc?.qty && !acc.bundling_id) {

                        returnValue += ((product.is_purchase ? acc.price : acc.rental_price) * (acc.qty ?? 0));
                        //console.log("In 3.2.1", returnValue);
                    }
                    if (acc.bundling_id) {
                        returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * (acc.qty ?? 0);
                        //console.log("In 3.2.2", acc, returnValue);
                    }
                }
            })
        }
    }
    //TH sản phẩm phối
    if (product.is_product === false) {
        returnValue = 0;
        returnValue += ((product.qty ?? 0)) * (product.is_purchase ? product.price : product.rental_price);
        product.accessories.forEach((acc: IProductAccessoryPayment) => {
            if (acc.checked) {
                if (acc.bundling_id) {
                    returnValue += (product.is_purchase ? ((isDeposit ? acc.original_price : acc.price) ?? 0) : ((isDeposit ? acc.original_rental_price : acc.rental_price) ?? 0)) * (acc.qty ?? 0);
                }
            }
        })
        if (!product.checked) return 0;
    }
    //console.log("returnValue", returnValue);

    return Math.round(returnValue);
}

export const totalPaymentRentPrice = (products: IProductDetailPayment[]) => {
    let totalRent: number = 0;

    for (let product of products) {
        if (!product.is_purchase) {
            let cloneAccessories = product.accessories?.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0) ?? []
            let isFull = checkIsFullSet(product, true);

            if (
                cloneAccessories.length === product?.temp_accessories?.length && product?.temp_accessories?.length > 0
                && !isFull && product.is_product === true
            ) {
                //console.log("In totalPaymentRentPrice 1");

                let is_bundling = false
                cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2?.qty ?? 0))

                let standardQty = 0;
                cloneAccessories.forEach((acc, index) => {
                    if (acc.checked && acc?.is_bundling === true) {
                        totalRent += (acc.original_price ?? 0) * (acc.qty ?? 0);
                        is_bundling = true
                    } else {
                        if (index === 0) {
                            standardQty = acc.qty ?? 0;

                            totalRent += (product.price ?? 0) * standardQty
                        } else if ((acc.qty ?? 0) - standardQty > 0) {
                            totalRent += (acc.price ?? 0) * ((acc.qty ?? 0) - standardQty);
                        }
                    }

                })
                if (is_bundling) {
                    totalRent += (product.price ?? 0) * (product.qty ?? 0)
                }

            } else if (product.accessories?.length && !isFull && product.is_product === true) {
                //console.log("In totalPaymentRentPrice 2", totalRent);
                let is_bundling = false
                cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2?.qty ?? 0))

                let standardQty = 0;
                cloneAccessories.forEach((acc, index) => {
                    if (acc.checked && acc?.bundling_id) {
                        //console.log("Inn add 1", (acc.original_price ?? 0) * (acc.qty ?? 0));
                        totalRent += (acc.original_price ?? 0) * (acc.qty ?? 0);
                        // if (!acc.bundling_id) {
                        //     is_bundling = true
                        // }

                    } else if (acc.checked) {
                        //console.log("Inn add 2");
                        // if (index === 0 && product.checked && !product.allowed_split) {
                        //     console.log("Inn add 2.1");
                        //     standardQty = acc.qty ?? 0;

                        //     totalRent += (product.price ?? 0) * standardQty
                        // } else if ((acc.qty ?? 0) - standardQty > 0 && standardQty != 0) {
                        //     console.log("Inn add 2.2", standardQty);
                        //     totalRent += (acc.price ?? 0) * ((acc.qty ?? 0) - standardQty);
                        // }
                        if (product.checked) {
                            totalRent += (acc.price ?? 0) * ((acc.qty ?? 0) - (product.qty ?? 0));
                        } else {
                            totalRent += (acc.price ?? 0) * (acc.qty ?? 0)
                        }
                        //console.log("Inn add 2.2", totalRent);
                        //Edit: Check set lẻ
                        // if (product.allowed_split) {
                        //     console.log("Inn add 2.3");
                        //     totalRent += (acc.price ?? 0) * (acc.qty ?? 0)
                        // }
                    }

                })
                if (product.checked) {
                    //console.log("Inn add");

                    totalRent += (product.price ?? 0) * (product.qty ?? 0)
                }
            } else {
                //console.log("In totalPaymentRentPrice 3", totalRent);
                if (!product.checked) continue;
                product.accessories.forEach((acc: IProductAccessoryPayment) => {
                    if (acc.checked && acc?.bundling_id) {
                        totalRent += (acc.original_price ?? 0) * (acc.qty ?? 0);
                        //console.log("In totalPaymentRentPrice 3.1-----------------", totalRent);
                    }
                    if (acc.checked && product.allowed_split && (acc.qty ?? 0) > (product.qty ?? 0) && !acc?.bundling_id) {
                        //console.log("In totalPaymentRentPrice 3.1");
                        totalRent += (acc.price ?? 0) * ((acc.qty ?? 0) - (product.qty ?? 0));
                    }
                    //TH ĐB 1 sp lẻ duy nhất
                    if (acc.checked && product.allowed_split && !acc?.bundling_id && cloneAccessories.length === 1) {
                        totalRent += (acc.price ?? 0) * (acc.qty ?? 0)
                    }
                })
                if (cloneAccessories.length === product?.temp_accessories?.length) {
                    totalRent += (product?.price ?? 0) * (product.qty ?? 0);
                }

                //console.log("In totalPaymentRentPrice 3.2-----------------", totalRent);
            }

            //TH sản phẩm phốirrrr
            // if (product?.is_product === false) {
            //     totalRent += (product.price ?? 0) * (product.qty ?? 0)
            // }
        }
    }

    //console.log("totalRent", totalRent);

    return Math.round(totalRent)
}


export const totalPayment = (products: IProductDetailPayment[], isDeposit: boolean) => {
    let totalSell: number = 0
    let totalRent: number = 0;
    //console.log('products', products);

    for (let product of products) {
        if (product.is_purchase) {
            totalSell += totalProduct(product, isDeposit);
        } else {
            totalRent += totalProduct(product, isDeposit);
        }
    }

    return {
        totalRent,
        totalSell
    }
}

export const checkProductIsFullSet = (product: IProductDetailPayment) => {
    let isFull = true;
    if (product.accessories?.length !== product?.temp_accessories?.length) {
        return false;
    }

    let full = checkIsFullSet(product);

    if (product.accessories?.length > 0 && !full) {
        // product.accessories.forEach((pro: IProductAccessoryPayment) => {
        //     if (pro.qty !== product.qty || !pro.checked) {
        //         isFull = false;
        //     }
        // })
    }

    return isFull;
}

export function checkHasBundling(items: any) {
    return items?.map((e: any) => {
        let findBundling = e.accessories?.find((item: any) => item?.bundling_id && item?.checked === true);
        if (!findBundling) {
            e.is_bundling = false;
            e.is_main_bundling = false;
        }
        e.accessories?.map((ie: any) => {
            if (!findBundling) {
                e.is_bundling = false
            }
            return ie;
        })
        return e;
    })
}

export const prepareDataValidate = (workingDay: Number[], holidays: IHolidays[], products: IProductDetailPayment[], generalSetting: IGeneralSetting, isRentDay = false, isPurchase = false, is_billing = false) => {
    let result: any = [];
    //console.log("prepareDataValidate", products);

    products.forEach((item: IProductDetailPayment) => {
        let isCheckFull = checkProductIsFullSet(item);
        // let isFull = checkIsFullSet(item);

        const takeDay = item.take_date ? moment(item.take_date) : null;
        const returnDay = item.return_date ? moment(item.return_date) : null;
        item.take_date = item.take_date ? moment(item.take_date).format("YYYY-MM-DD") : "";
        item.return_date = item.return_date ? moment(item.return_date).format("YYYY-MM-DD") : "";
        let diffDay = calSurchargeDay(workingDay, holidays, new Date(item.take_date), new Date(item.return_date), generalSetting.time_rent_package) ?? 0;
        if (isRentDay) {
            diffDay = calSurchargeDay(workingDay, holidays, new Date(item.take_date), new Date(item.return_date), generalSetting.time_rent_package) ?? 0;
        }
        if (is_billing === true) {
            diffDay += generalSetting.time_rent_package;
        }
        let cloneAccessories = [...item.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];

        if ((isCheckFull || item.is_main_bundling)) {
            if (item.checked) {
                //console.log("rent 1");

                if (item.is_product === false) {
                    if (isPurchase) {
                        result.push({
                            //product_detail_code: item.code,
                            //product_accessories_code: null,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            product_mix_code: item.code,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 1.1");
                        result.push({
                            //product_detail_code: item.code,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            product_mix_code: item.code,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else if (item.bundling_id) {
                    if (isPurchase) {
                        result.push({
                            original_product: item.code,
                            product_detail_code: item.code,
                            product_bundling_id: item.bundling_id,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 1.2");
                        result.push({
                            original_product: item.code,
                            product_detail_code: item.code,
                            product_bundling_id: item.bundling_id,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else {
                    if (isPurchase) {
                        result.push({
                            product_detail_code: item.code,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 1.3");
                        result.push({
                            product_detail_code: item.code,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        });

                    }

                }


                cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {

                    if (acc?.bundling_id) {
                        result.push({
                            original_product: item.code,
                            product_detail_code: acc.code,
                            product_bundling_id: acc?.bundling_id,
                            //product_accessories_code: null,
                            amount: acc.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }
                })
            }
            if (cloneAccessories.length === item.temp_accessories?.length && item.temp_accessories?.length > 0 && item.is_product) {
                //console.log("rent 1.4");
                cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
                cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                    if ((item.qty ?? 0) < (acc.qty ?? 0) && !acc.bundling_id) {
                        result.push({
                            product_detail_id: item.id,
                            product_detail_code: item.code,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0) - (item.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }
                })
            } else if (isCheckFull) {
                //console.log("rent 1.5", isCheckFull);
                item.accessories.forEach((acc: IProductAccessoryPayment) => {
                    if (acc.checked) {
                        //let diffDay = (returnDay?.diff(takeDay, 'days') ?? 0) - (generalSetting.time_rent_package ?? 0)
                    }
                })

                let cloneAccessories = [...item.accessories.filter((item: IProductAccessoryPayment | any) => item.checked && item.qty > 0)];

                if (cloneAccessories.length === item.temp_accessories?.length && item.temp_accessories?.length > 0 && (item?.is_product === true || item?.is_bundling === true)) {
                    cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))

                    let standardQty = 0;
                    cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {

                        if (index === 0) {
                            if ((acc.qty ?? 0) > 0) {
                                standardQty = acc.qty ?? 0;
                                if (item?.is_product === false) {
                                    if (isPurchase) {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            amount: standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            amount: standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else if (acc?.bundling_id) {
                                    if (isPurchase) {
                                        //console.log("rent 1.6");
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: acc.code,
                                            product_bundling_id: acc.bundling_id,
                                            //product_accessories_code: null,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        //console.log("rent 1.7");
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: acc.code,
                                            product_bundling_id: acc.bundling_id,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            //product_accessories_code: null,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else {
                                    if (isPurchase) {
                                        result.push({
                                            product_detail_code: item.code,
                                            amount: standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            product_detail_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            amount: standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                }


                            }
                        } else {
                            //console.log("acc.qty", acc.qty, standardQty);

                            if ((acc.qty ?? 0) - standardQty > 0) {
                                // let diffDay = (returnDay?.diff(takeDay, 'days') ?? 0) - (generalSetting.time_rent_package ?? 0)
                                //
                                // if(isRentDay) {
                                //     diffDay = (returnDay?.diff(takeDay, 'days') ?? 0)
                                // }
                                if (item?.is_product === false) {
                                    if (isPurchase) {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            product_accessories_code: acc.code,
                                            amount: (acc.qty ?? 0) - standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            product_accessories_code: acc.code,
                                            amount: (acc.qty ?? 0) - standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else if (acc?.bundling_id) {

                                    if (isPurchase) {
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: acc.code,
                                            product_bundling_id: acc?.bundling_id,
                                            //product_accessories_code: null,
                                            amount: item.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        //console.log("rent 1.8");
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: acc.code,
                                            product_bundling_id: acc?.bundling_id,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            //product_accessories_code: null,
                                            amount: item.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else {
                                    if (isPurchase) {
                                        result.push({
                                            product_detail_code: item.code,
                                            product_accessories_code: acc.code,
                                            amount: (acc.qty ?? 0) - standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            product_detail_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            product_accessories_code: acc.code,
                                            amount: (acc.qty ?? 0) - standardQty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                }

                            }

                        }
                    })
                } else if (!isCheckFull) {
                    if (item?.is_product === false) {
                        if (isPurchase) {
                            result.push({
                                //product_detail_code: item.code,
                                product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                product_mix_code: item.code,
                                amount: item.qty,
                                rent_day: diffDay > 0 ? diffDay : 0,
                            })
                        } else {
                            result.push({
                                //product_detail_code: item.code,
                                product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                product_mix_code: item.code,
                                received_date: item.take_date,
                                return_date: item.return_date,
                                amount: item.qty,
                                rent_day: diffDay > 0 ? diffDay : 0,
                            })
                        }

                    } else if (item.is_bundling === true) {
                        //console.log("item", item);

                        if (isPurchase) {
                            result.push({
                                original_product: item.code,
                                product_detail_code: item.code,
                                product_bundling_id: item.bundling_id,
                                //product_accessories_code: null,
                                amount: item.qty,
                                rent_day: diffDay > 0 ? diffDay : 0,
                            })
                        } else {
                            //console.log("test");
                            //console.log("rent 1.9");
                            result.push({
                                original_product: item.code,
                                product_detail_code: item.code,
                                product_bundling_id: item.bundling_id,
                                received_date: item.take_date,
                                return_date: item.return_date,
                                //product_accessories_code: null,
                                amount: item.qty,
                                rent_day: diffDay > 0 ? diffDay : 0,
                            })
                        }

                    } else {
                        item.accessories.forEach((acc: IProductAccessoryPayment) => {
                            if (acc.checked) {
                                //let diffDay = (returnDay?.diff(takeDay, 'days') ?? 0) - (generalSetting.time_rent_package ?? 0)
                                if (item?.is_product === false) {
                                    if (isPurchase) {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            product_accessories_code: acc.code,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            //product_detail_code: item.code,
                                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                            product_mix_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            product_accessories_code: acc.code,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else if (item.is_bundling === true) {
                                    //console.log("item", item);

                                    if (isPurchase) {
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: item.code,
                                            product_bundling_id: item.bundling_id,
                                            //product_accessories_code: null,
                                            amount: item.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        //console.log("rent 1.9");
                                        result.push({
                                            original_product: item.code,
                                            product_detail_code: item.code,
                                            product_bundling_id: item.bundling_id,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            //product_accessories_code: null,
                                            amount: item.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                } else {
                                    if (isPurchase) {
                                        result.push({
                                            product_detail_code: item.code,
                                            product_accessories_code: acc.code,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    } else {
                                        result.push({
                                            product_detail_code: item.code,
                                            received_date: item.take_date,
                                            return_date: item.return_date,
                                            product_accessories_code: acc.code,
                                            amount: acc.qty,
                                            rent_day: diffDay > 0 ? diffDay : 0,
                                        })
                                    }

                                }

                            }
                        })
                    }

                }

                if (cloneAccessories.length !== item.temp_accessories?.length && item.checked) {
                    cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                        let standardQty = item.qty ?? 0;
                        if ((acc.qty ?? 0) - standardQty > 0) {
                            if (item?.is_product === false) {
                                if (isPurchase) {
                                    result.push({
                                        //product_detail_code: item.code,
                                        product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                        product_mix_code: item.code,
                                        product_accessories_code: acc.code,
                                        amount: (acc.qty ?? 0) - standardQty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                } else {
                                    result.push({
                                        //product_detail_code: item.code,
                                        product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                                        product_mix_code: item.code,
                                        received_date: item.take_date,
                                        return_date: item.return_date,
                                        product_accessories_code: acc.code,
                                        amount: (acc.qty ?? 0) - standardQty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                }

                            } else if (acc?.bundling_id) {

                                if (isPurchase) {
                                    result.push({
                                        original_product: item.code,
                                        product_detail_code: acc.code,
                                        product_bundling_id: acc?.bundling_id,
                                        //product_accessories_code: null,
                                        amount: item.qty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                } else {
                                    //console.log("rent 1.8");
                                    result.push({
                                        original_product: item.code,
                                        product_detail_code: acc.code,
                                        product_bundling_id: acc?.bundling_id,
                                        received_date: item.take_date,
                                        return_date: item.return_date,
                                        //product_accessories_code: null,
                                        amount: item.qty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                }

                            } else {
                                if (isPurchase) {
                                    result.push({
                                        product_detail_code: item.code,
                                        product_accessories_code: acc.code,
                                        amount: (acc.qty ?? 0) - standardQty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                } else {
                                    result.push({
                                        product_detail_code: item.code,
                                        received_date: item.take_date,
                                        return_date: item.return_date,
                                        product_accessories_code: acc.code,
                                        amount: (acc.qty ?? 0) - standardQty,
                                        rent_day: diffDay > 0 ? diffDay : 0,
                                    })
                                }

                            }

                        }
                    })
                }
            }
        } else {
            //console.log("rent 2");

            if (item.checked && item.accessories.length === item.temp_accessories.length) {
                //console.log("rent 2.1");

                if (item.is_product === false) {
                    if (isPurchase) {
                        result.push({
                            //product_detail_code: item.code,
                            //product_accessories_code: null,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            product_mix_code: item.code,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 2.2");
                        result.push({
                            //product_detail_code: item.code,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            product_mix_code: item.code,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else if (item.bundling_id) {
                    if (isPurchase) {
                        result.push({
                            original_product: item.code,
                            product_detail_code: item.code,
                            product_bundling_id: item.bundling_id,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 2.3");
                        result.push({
                            original_product: item.code,
                            product_detail_code: item.code,
                            product_bundling_id: item.bundling_id,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else {
                    if (isPurchase) {
                        result.push({
                            product_detail_code: item.code,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 2.4");
                        result.push({
                            product_detail_code: item.code,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                }


                cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {

                    if (acc?.bundling_id) {
                        result.push({
                            original_product: item.code,
                            product_detail_code: acc.code,
                            product_bundling_id: acc?.bundling_id,
                            //product_accessories_code: null,
                            amount: acc.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }
                })
            }
            if (cloneAccessories.length === item.temp_accessories?.length && item.temp_accessories?.length > 0) {
                //console.log("rent 2.5");
                cloneAccessories.sort((item1, item2) => (item1.qty ?? 0) - (item2.qty ?? 0))
                cloneAccessories.forEach((acc: IProductAccessoryPayment, index: number) => {
                    if ((item.qty ?? 0) < (acc.qty ?? 0)) {
                        result.push({
                            product_detail_id: item.id,
                            product_detail_code: item.code,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0) - (item.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }
                })
            }
        }

        //TH ĐB chỉ có 1 món lẻ duy nhất
        //Xử lý set lẻ
        //console.log("Inn 123900");
        if ((!item.checked && item.allowed_split) || (item.checked && item.allowed_split && item.accessories.length === 1)) {
            //console.log("Inn 123900");
            cloneAccessories?.map((acc: any) => {
                if (item?.is_product === false) {
                    if (isPurchase) {
                        result.push({
                            //product_detail_code: item.code,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            product_mix_code: item.code,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        result.push({
                            //product_detail_code: item.code,
                            product_details: item.accessories?.map((e: IProductAccessoryPayment) => e.code),
                            product_mix_code: item.code,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else if (acc?.bundling_id) {

                    if (isPurchase) {
                        result.push({
                            original_product: item.code,
                            product_detail_code: acc.code,
                            product_bundling_id: acc?.bundling_id,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        //console.log("rent 1.8");
                        result.push({
                            original_product: item.code,
                            product_detail_code: acc.code,
                            product_bundling_id: acc?.bundling_id,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            //product_accessories_code: null,
                            amount: item.qty,
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                } else {
                    if (isPurchase) {
                        result.push({
                            product_detail_code: item.code,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    } else {
                        result.push({
                            product_detail_code: item.code,
                            received_date: item.take_date,
                            return_date: item.return_date,
                            product_accessories_code: acc.code,
                            amount: (acc.qty ?? 0),
                            rent_day: diffDay > 0 ? diffDay : 0,
                        })
                    }

                }

                return acc;
            })
        }

    })

    //console.log("result", result);

    return result;
}

export const checkHasData = (carts: IProductDetailPayment[]) => {
    //console.log("carts", carts);

    let hasData = false;
    carts.forEach((cart) => {

        cart.accessories.forEach((acc) => {
            if (acc.checked) hasData = true;
        });

        if (cart.checked) {
            hasData = true
        } else {
            //hasData = false;
        };
    })
    return hasData;
}

export const errorAmount = (pro: any) => {

    let error = ""
    if (pro.bundling_id) {
        if (pro.maximum_order && pro.qty > pro.maximum_order) {
            error = `Số lượng tối đa ${pro.maximum_order}`
        }
    } else {
        // if (pro.amount && pro.qty > pro.amount) {
        //     error = `Số lượng tối đa ${pro.amount}`
        // }
    }
    return error
}

export const checkIsFullSet = (pro: IProductDetailPayment, hasChecked = false) => {
    let flag = false;

    pro.temp_accessories?.forEach((acc: any) => {
        let item: any = pro.accessories?.find((_item) => _item.code === acc.code);


        if (item && item.qty !== acc.qty) { flag = true; }
    })

    if (pro.is_set === true) {
        let isValid = true;
        pro.accessories?.forEach((acc: any) => {
            if (!acc.checked) {
                isValid = false;
            }
        })
        if (!isValid) {
            return false
        }
    }

    if (
        (
            pro.accessories?.length !== pro?.temp_accessories?.length
            || (pro.temp_accessories?.length > 0 && flag)
        )
        || pro.temp_accessories?.length === 0
    ) {
        if ((pro?.qty || 0) > 0)
            return true;
    }

    return false
}
